import request from '@/utils/request'

export function lysConfigApi (data) {
  return request({
    method: 'post',
    url: 'common/lysConfig',
    data: data,
    isToken: true
  })
}

export function loginApi (data) {
  return request({
    method: 'post',
    url: 'user/login',
    data: data,
    isToken: true
  })
}

// 省市区
export function getCascaderCityApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getCityCascaderOption',
    data: data,
    isToken: false
  })
}

// 省市
export function getCascaderCityOptionApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getCityCascaderCityOption',
    data: data,
    isToken: false
  })
}

export function getPropertyCategoryApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getPropertyCategory',
    data: data,
    isToken: false
  })
}

export function getMediaCategoryApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getMediaCategory',
    data: data,
    isToken: false
  })
}

export function getMediaCompanyApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getMediaCompany',
    data: data,
    isToken: false
  })
}

export function getMediaProjectApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getMediaProject',
    data: data,
    isToken: false
  })
}

export function getTimeSelectApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getTimeSelect',
    data: data,
    isToken: false
  })
}

export function getTabooSelectApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getTabooSelect',
    data: data,
    isToken: false
  })
}

export function getInstallPositionApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getInstallPosition',
    data: data,
    isToken: false
  })
}

export function getDayPlayDataApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getDayPlayData',
    data: data,
    isToken: false
  })
}

export function getMediaProjectsApi (data = {}) {
  return request({
    method: 'post',
    url: 'basic/getMediaProjects',
    data: data,
    isToken: false
  })
}

export function getCaptchaApi (data = {}) {
  return request({
    method: 'post',
    url: 'user/getCaptcha',
    data: data,
    isToken: false
  })
}

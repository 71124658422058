import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const changePush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return changePush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/user.vue')
  },
  {
    path: '/market/city',
    name: 'cityData',
    component: () => import(/* webpackChunkName: "about" */ '../views/market/cityData.vue')
  },
  {
    path: '/market/media',
    name: 'mediaData',
    component: () => import(/* webpackChunkName: "about" */ '../views/market/mediaData.vue')
  },
  {
    path: '/market/risk',
    name: 'riskData',
    component: () => import(/* webpackChunkName: "about" */ '../views/market/riskData.vue')
  },
  {
    path: '/market/chance',
    name: 'chanceData',
    component: () => import(/* webpackChunkName: "about" */ '../views/market/chanceData.vue')
  },
  {
    path: '/point/city',
    name: 'cityView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/city.vue')
  },
  {
    path: '/point/way',
    name: 'wayView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/way.vue')
  },
  {
    path: '/point/audiences',
    name: 'audiencesView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/audiences.vue')
  },
  {
    path: '/point/feature',
    name: 'featureView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/feature.vue')
  },
  {
    path: '/point/mediaType',
    name: 'mediaTypeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/mediaType.vue')
  },
  {
    path: '/point/interfere',
    name: 'interfereView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/interfere.vue')
  },
  {
    path: '/point/company',
    name: 'companyView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/company.vue')
  },
  {
    path: '/point/installPosition',
    name: 'installPositionView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/installPosition.vue')
  },
  {
    path: '/point/sameBuilding',
    name: 'sameBuildingView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/sameBuilding.vue')
  },
  {
    path: '/point/exposure',
    name: 'exposureView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/exposure.vue')
  },
  {
    path: '/point/dayPlay',
    name: 'dayPlayView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/dayPlay.vue')
  },
  {
    path: '/point/sameLat',
    name: 'sameLatView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/sameLat.vue')
  },
  {
    path: '/point/sceneOptimize',
    name: 'sceneOptimizeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/sceneOptimize.vue')
  },
  {
    path: '/point/coverWay',
    name: 'coverWayView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/coverWay.vue')
  },
  {
    path: '/point/efficiencyFirst',
    name: 'efficiencyFirstView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/efficiencyFirst.vue')
  },
  {
    path: '/point/custom',
    name: 'customView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/custom.vue')
  },
  {
    path: '/point/target',
    name: 'targetView',
    component: () => import(/* webpackChunkName: "about" */ '../views/point/target.vue')
  },
  {
    path: '/report/report',
    name: 'reportView',
    component: () => import(/* webpackChunkName: "about" */ '../views/report/report.vue')
  },
  {
    path: '/report/reportShow',
    name: 'reportShowView',
    component: () => import(/* webpackChunkName: "about" */ '../views/report/reportShow.vue')
  },
  {
    path: '/report/check',
    name: 'checkView',
    component: () => import(/* webpackChunkName: "about" */ '../views/report/check.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import { lysConfigApi } from '@/api/basic'

export default {
  name: 'lysApp',
  data () {
    return {}
  },
  mounted () {
    console.log('app mounted')
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.$store.commit('setUserInfo', user)
    }
    this.lysConfig()
  },
  methods: {
    lysConfig () {
      lysConfigApi().then((res) => {
        this.$store.commit('setLysConfig', res.data)
        this.$reslove() // 其他页面，等待此方法执行后，再执行，使用async await
      }).catch((err) => {
        console.log('getCaptchaApi-err', err)
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

.el-cascader__search-input::placeholder {
  //color: #333333;
  font-size: 14px;
}

.el-cascader__tags {
  font-size: 14px;
  max-height: 290px;
  //position: absolute;
  //left: 0;
  //right: 0.15625rem;
  //top: 50%;
  //transform: translateY(-50%);
  //display: flex;
  //flex-wrap: nowrap;
  //line-height: normal;
  //text-align: left;
  //box-sizing: border-box;
}
</style>

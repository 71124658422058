import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      token: '',
      id: 0,
      isLogin: false,
      avatar: '',
      mobile: '',
      nickname: '',
      username: '',
      email: '',
      level: 0,
      user_id: 0
    },
    imageUrl: 'https://louyoushu.oss-cn-shenzhen.aliyuncs.com',
    lysConfig: {},
    configLoading: false
  },
  getters: {},
  mutations: {
    setUserInfo (state, payload) {
      console.log('11111-----setUserInfo------', state, payload)
      for (const i in payload) {
        for (const j in state.user) {
          if (i === j) {
            state.user[j] = payload[i]
          }
        }
      }
      if (payload.isLogin) {
        state.user.isLogin = true
      }
      // 保存至缓存
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    isLogin (state) {
      return state.user.isLogin
    },
    getUserInfo (state) {
      console.log('getUserInfo------', state)
      return state.user
    },
    setLysConfig (state, payload) {
      state.lysConfig = payload
      state.configLoading = true
    }
  },
  actions: {},
  modules: {}
})

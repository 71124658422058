import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/main.scss'
import 'amfe-flexible' // 为html、body添加font-size,窗口调整时自动重新设置font-size

Vue.config.productionTip = false
Vue.use(ElementUI)

// eslint-disable-next-line promise/param-names
Vue.prototype.$onWait = new Promise((r) => {
  Vue.prototype.$reslove = r
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

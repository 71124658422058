/**
 * 接口请求
 * by ali-zhp
 * @type {string}
 */
import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'

let BASE_URL = ''
const TIME_OUT = 10000

if (process.env.NODE_ENV === 'development') { // 开发
  BASE_URL = 'https://zhp.lys.com/api/'
} else if (process.env.NODE_ENV === 'production') { // 正式
  BASE_URL = 'https://lysadmin.zhp888.cn/api/'
}

// 创建axios实例
const request = axios.create({
  baseURL: BASE_URL, // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
  withCredentials: false, // 跨域请求时是否需要访问凭证
  timeout: TIME_OUT // 请求超时时间
})

// 请求拦截器
request.interceptors.request.use((config) => {
  config.headers['Content-type'] = 'application/json'
  // 携带token
  if (config.isToken) {
    config.headers.token = getToken()
  }
  return config
}, (error) => {
  Message.error('网络异常,请稍后再试' + error)
  return Promise.reject(error)
})

request.interceptors.response.use((response) => {
  const {
    code,
    msg
  } = response.data
  if (code !== 1) {
    Message({
      message: msg || 'error',
      type: 'warning'
    })
  }
  return response.data
}, (error) => {
  Message.error(error.response.data.msg || '网络异常,请稍后再试')
  if (error.response.data.code === 401) {
    // 跳转登录页
    router.push('/login').then(r => {
    })
    return false
  }
  return Promise.reject(error.response)
})

export default request

/**
 * 获取token，登录后保存
 * @returns {string|string}
 */
function getToken () {
  // const token = localStorage.getItem('lys-token')
  const token = store.state.user.token
  return token || ''
}
